<template>
  <div class="wytg">
    <ShowDetail
      v-if="showDetail"
      @closeDialog2="closeDialog2"
      ref="showDetail"
    />
    <div class="form-list">
      <div class="table-search">
        <h3>收藏的服务商</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery
          ref="formquery"
          :data="Query"
          :key="keyForm"
          @submit="search"
          @reset="reset"
        >
        </FormQuery>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.current"
          :pageSize="query.size"
          :total="total"
          :border="true"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        >
          <template slot="serviceScope" slot-scope="scope">
            <div class="text-container">
              <span class="two-line-ellipsis" :title="scope.row.serviceScope">{{
                scope.row.serviceScope
              }}</span>
            </div>
          </template>
          <template slot="serviceType" slot-scope="scope">
            <span>{{
              scope.row.serviceType == 1 ? "全程托管" : "环节托管"
            }}</span>
          </template>
          <el-table-column prop="companyName" label="农服商名称">
          </el-table-column>
          <el-table-column prop="serviceScope" label="作物类型">
            <template slot-scope="scope">
              <div class="text-container">
                <span
                  class="two-line-ellipsis"
                  :title="scope.row.serviceScope"
                  >{{ scope.row.serviceScope }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="serviceType" label="服务类型">
            <template slot-scope="scope">
              <span>{{
                scope.row.serviceType == 1 ? "全程托管" : "环节托管"
              }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="intentionRegion" label="意向区域">
          </el-table-column>
          <el-table-column prop="serviceArea" label="可接管面积">
          </el-table-column>
          <el-table-column prop="serviceAreaOlde" label="已接管面积">
          </el-table-column>
          <el-table-column prop="createTime" label="发布时间">
          </el-table-column>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="detailClick(scope.row)"
              >查看</el-button
            >
            <el-button type="text" size="small" @click="noSelect(scope.row)"
              >取消收藏</el-button
            >
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
<script>
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/table-page";
import ShowDetail from "./showDetail";
import { scdnfsColumn } from "../tableColumn";
import { getCollectionToPage, selectCompanyLand } from "@/api/projectManage";
import { updateCollection, getXzqArr, getBreedInTrust } from "@/api/add";
import { removeEmptyChildren } from "@/js/utils/util";
import { number } from "echarts";
// const Query = [
//   {
//     name: "areaCode",
//     label: "地区：",
//     componentType: "cascader",
//     placeholder: "请选择",
//     width: "180px",
//     props: {
//       label: "areaName",
//       children: "children",
//       value: "id",
//       checkStrictly: true,
//     },
//     dataOptions: [],
//   },
//   {
//     name: "cropCode",
//     label: "作物类型：",
//     componentType: "input",
//     inputType: "",
//     placeholder: "",
//     width: "160px",
//   },
//   {
//     name: "orderType",
//     label: "服务类型：",
//     componentType: "select",
//     placeholder: "请选择",
//     width: "160px",
//     dataOptions: [
//       {
//         name: "全程托管",
//         value: 1,
//       },
//       {
//         name: "环节托管",
//         value: 2,
//       },
//     ],
//   },
// ];
const Query = [
  {
    name: "areaCode",
    label: "地区：",
    componentType: "cascader",
    placeholder: "请选择",
    width: "180px",
    props: {
      label: "areaName",
      children: "children",
      value: "id",
      checkStrictly: true,
      expandTrigger: "hover",
    },
    dataOptions: [],
  },
  {
    name: "companyName",
    label: "农服商名称：",
    componentType: "input",
    placeholder: "",
    width: "140px",
    inputType: "",
  },
  {
    name: "serviceType",
    label: "服务类型：",
    componentType: "select",
    placeholder: "请选择",
    width: "120px",
    dataOptions: [
      {
        name: "全程托管",
        value: 1,
      },
      {
        name: "环节托管",
        value: 2,
      },
    ],
  },
  {
    name: "serviceScope",
    label: "作物类型：",
    componentType: "select",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    filterable: true,
    dataOptions: [],
  },
];
export default {
  components: {
    FormQuery,
    TablePage,
    ShowDetail,
  },
  filters: {
    name1Filter(value) {
      return value == 1 ? "全程托管" : "环节托管";
    },
  },
  data() {
    return {
      baseUrl: window.globalUrl.HOME_API,
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {},
      showDetail: false,
      Query: Query,
      query: {
        name: "",
        current: 1,
        size: 10,
      },
      total: 0,
      tableData: [],
      columns: scdnfsColumn,
      form1Params: {},
      serviceType: [
        {
          item: "1",
          name: "全程托管",
        },
        {
          item: "2",
          name: "环节托管",
        },
      ],
      // serviceScope:[],
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      // currentItem:{}
      // options: [
      //   { value: 0, label: "已成交" },
      //   { value: 1, label: "未成交" },
      // ],
    };
  },
  created() {
    this.getRegions();
    this.getList();
    this.gettypes();
  },
  methods: {
    async gettypes() {
      const res = await getBreedInTrust();
      if (res.code == 0) {
        res.data.forEach((item) => {
            item.label = item.categoryName;
        item.value = item.categoryName;
        });
      }
      this.Query[3].dataOptions=res.data
      console.log("1212313",res.data);
      // this.serviceScope=res.data
    },

    // 关闭详情
    closeDialog2() {
      this.showDetail = false;
    },
    // 点击详情
    detailClick(item) {
      console.log(item.photos);
      let xczp = [];
      if (item.photos) {
        JSON.parse(item.photos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }

      // 经营主体照片
      let companyPhoto = []; // 初始化一个空数组用于存储处理后的图片路径
      if (typeof item.companyPhoto === "string" && item.companyPhoto !== "") {
        // 如果 item.companyPhoto 是字符串类型
        const arr = item.companyPhoto.split(","); // 将字符串按逗号分割成数组
        arr.forEach((photoItem) => {
          // 修改此处的参数名，避免与外部的 item 冲突
          companyPhoto.push(
            this.baseUrl + "admin/file/get?ossFilePath=" + photoItem // 构造完整的图片路径并添加到数组中
          );
        });
      } else {
        companyPhoto = item.companyPhoto;
      }
      item.companyPhoto = companyPhoto; // 更新原始数据中的 companyPhoto 字段

      item.xczp = xczp;
      // this.currentItem = item;
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showDetail.init(item);
      });
      console.log("wxf1111");
    },
    async getRegions() {
      // 获取地区信息
      let res = await getXzqArr({
        level: 5,
      });
      if (res.code == 0) {
        let hasRegion = setInterval(() => {
          this.Query[0].dataOptions = removeEmptyChildren(res.data);
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
      }
    },
    // 取消收藏
   async noSelect(obj) {
      console.log(obj);
      this.$confirm("确定取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await updateCollection({ companyServiceId: obj.id });
          if (res.code == 0) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 分页点击
    handleCurrentChange(page) {
      this.query.current = page;
      this.getList();
    },
    // 分页数量改变
    handleSizeChange(size) {
      this.query.current = 1;
      this.query.size = size;
      this.getList();
    },
    // 获取列表
    async getList() {
      let res = await selectCompanyLand({ ...this.query, ...this.form1Params });
      if (res.code == 0) {
        this.tableData = res.data.records;
        this.total = Number(res.data.total);
      } else {
        this.$message.error("查询失败");
      }
    },
    // 条件查询
    search(params) {
      console.log(params);
      this.form1Params = {
        areaCode: params.areaCode
          ? params.areaCode[params.areaCode.length - 1]
          : null,
        // intentionRegionCode: params.intentionRegionCode
        //   ? params.intentionRegionCode[params.intentionRegionCode.length - 1]
        //   : null,
        companyName: params.companyName,
        serviceType: params.serviceType,
        serviceScope: params.serviceScope,
      };

      this.query = { ...this.query, ...this.form1Params };
      this.getList();
    },
    // 条件重置
    reset() {
      this.form1Params = {};
      this.query = {
        current: 1,
        size: 10,
      };
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
    margin-top: 6px;
  }
}
</style>
